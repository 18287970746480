import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryComponentOptions } from '@apollo/react-components'
import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from '@apollo/react-hooks'
import {
  statistics,
  product,
  products,
  productVariables,
  settings,
  valueCodeGroups,
  deprecatedReceipts,
  deprecatedReceipt,
  deprecatedReceiptVariables,
  receipts,
  receipt,
  receiptVariables,
  updateMe,
  updateMeVariables,
  valueCodesQuery,
  valueCodesQueryVariables,
} from '../../core-types'

const ME_FRAGMENT = gql`
  fragment GlobalMe on Me {
    id
    preferredLanguageCode
  }
`
export const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on Company {
    id
    name
    organizationNumber
    address
    zipCode
    city
    receiptEmail
    active
    collectionMethod
  }
`

export const SETTINGS_QUERY = gql`
  ${COMPANY_FRAGMENT}
  ${ME_FRAGMENT}
  query settings {
    intercomSettings
    system {
      id
      name
      languageCodes
      currency
      publicDomain
      webUrl
      businessUrl
      contactEmail
      contactNumber
      paymentProvider
    }
    me {
      ...GlobalMe
    }
    company {
      ...CompanyFragment
    }
    haveSanityBusinessPage
  }
`

export const SettingsQuery = (
  props: Omit<QueryComponentOptions<settings>, 'query'>
) => {
  return <Query<settings> query={SETTINGS_QUERY} {...props}></Query>
}

export const useSettingsQuery = (options?: QueryHookOptions<settings>) =>
  useQuery<settings>(SETTINGS_QUERY, options)

const PRODUCTS_QUERY = gql`
  query products {
    products {
      id
      name
      priceWithoutVat
      description
    }
  }
`

export const ProductsQuery = (
  props: Omit<QueryComponentOptions<products>, 'query'>
) => {
  return <Query<products> query={PRODUCTS_QUERY} {...props} />
}

const PRODUCT_QUERY = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      name
      priceWithoutVat
      description
      imageUrl(options: { width: 800 })
    }
  }
`

export const ProductQuery = (
  props: Omit<QueryComponentOptions<product, productVariables>, 'query'>
) => {
  return <Query<product, productVariables> query={PRODUCT_QUERY} {...props} />
}

const VALUE_CODE_GROUPS_QUERY = gql`
  query valueCodeGroups {
    valueCodeGroups {
      groupName
    }
  }
`

export const ValueCodeGroupsQuery = (
  props: Omit<QueryComponentOptions<valueCodeGroups>, 'query'>
) => {
  return <Query<valueCodeGroups> query={VALUE_CODE_GROUPS_QUERY} {...props} />
}

export const VALUE_CODES_QUERY = gql`
  query valueCodesQuery(
    $groupName: String
    $state: ValueCodeState
    $perPage: Int
    $page: Int
    $all: Boolean
  ) {
    valueCodes(
      groupName: $groupName
      state: $state
      page: $page
      perPage: $perPage
      all: $all
    ) {
      count
      result {
        id
        code
        createdAt
        groupName
        state
        transport
        receiverEmail
        product {
          id
          name
        }
        administrator {
          id
          name
        }
      }
    }
  }
`

export const ValueCodesQuery = (
  props: Omit<
    QueryComponentOptions<valueCodesQuery, valueCodesQueryVariables>,
    'query'
  >
) => {
  return (
    <Query<valueCodesQuery, valueCodesQueryVariables>
      query={VALUE_CODES_QUERY}
      {...props}
    />
  )
}

const STATISTICS_QUERY = gql`
  query statistics {
    statistics {
      name
      sharedCodes
      claimedCodes
    }
  }
`

export const StatisticsQuery = (
  props: Omit<QueryComponentOptions<statistics>, 'query'>
) => {
  return <Query<statistics> query={STATISTICS_QUERY} {...props} />
}

export const DeprecatedReceiptsQuery = (
  props: Omit<QueryComponentOptions<deprecatedReceipts>, 'query'>
) => {
  return (
    <Query<deprecatedReceipts>
      query={gql`
        query deprecatedReceipts {
          orders {
            id
            status
            type
            amount
            amountWithoutVat
            currency
            createdAt
          }
        }
      `}
      {...props}
    />
  )
}

export const DeprecatedReceiptQuery = (
  props: Omit<
    QueryComponentOptions<deprecatedReceipt, deprecatedReceiptVariables>,
    'query'
  >
) => {
  return (
    <Query<deprecatedReceipt, deprecatedReceiptVariables>
      query={gql`
        query deprecatedReceipt($id: ID!) {
          order(id: $id) {
            id
            status
            type
            amount
            amountWithoutVat
            vatAmount
            currency
            createdAt
            customerEmail
            orderLines {
              id
              product {
                id
                description
                name
                priceWithoutVat
              }
              quantity
              amountWithoutVat
              totalAmountWithoutVat
              vatRate
              valueCodes {
                id
                code
                redeemedAt
              }
            }
            paymentTransactions {
              id
              transactionNumber
            }
            creditCardMasked
            company {
              id
              name
            }
          }
        }
      `}
      {...props}
    />
  )
}

const UPDATE_ME_MUTATION = gql`
  ${ME_FRAGMENT}
  mutation updateMe($input: MeInput!) {
    updateMe(input: $input) {
      ...GlobalMe
    }
  }
`

export const useUpdateMeMutation = (
  options?: Omit<MutationHookOptions<updateMe, updateMeVariables>, 'update'>
) =>
  useMutation<updateMe, updateMeVariables>(UPDATE_ME_MUTATION, {
    ...options,
    update: (cache, { data }) => {
      if (!data) {
        return
      }
      const cachedData = cache.readQuery<settings>({ query: SETTINGS_QUERY })
      if (!data || !data.updateMe || !cachedData || !cachedData.me) {
        return
      }
      cache.writeQuery<settings>({
        query: SETTINGS_QUERY,
        data: { ...cachedData, me: { ...cachedData.me, ...data.updateMe } },
      })
    },
  })

export const RECEIPT_QUERY = gql`
  query receipt($token: String!) {
    invoice(token: $token) {
      id
      status
      description
      collectionMethod
      total
      totalVat
      totalWithoutVat
      currency
      paidAt
      periodStart
      periodEnd
      token
      createdAt
      currency
      createdAt
      items {
        id
        product {
          id
          descriptionText
          name
          price
          vatRate
        }
        quantity
        amount
        totalAmount
        amountWithoutVat
        totalWithoutVat
      }
      transactionNumber
      paymentMethodDetails {
        type
        card {
          expMonth
          expYear
          last4
          brand
        }
      }
      company {
        id
        name
        receiptEmail
      }
    }
  }
`

export const ReceiptQuery = (
  props: Omit<QueryComponentOptions<receipt, receiptVariables>, 'query'>
) => {
  return <Query<receipt, receiptVariables> query={RECEIPT_QUERY} {...props} />
}

export const ReceiptsQuery = (
  props: Omit<QueryComponentOptions<receipts>, 'query'>
) => {
  return (
    <Query<receipts>
      query={gql`
        query receipts(
          $draft: Boolean
          $open: Boolean
          $paid: Boolean
          $uncollectible: Boolean
          $sendInvoice: Boolean
          $chargeAutomatically: Boolean
        ) {
          invoices(
            draft: $draft
            open: $open
            paid: $paid
            uncollectible: $uncollectible
            sendInvoice: $sendInvoice
            chargeAutomatically: $chargeAutomatically
          ) {
            rows {
              id
              status
              description
              collectionMethod
              total
              totalVat
              totalWithoutVat
              currency
              paidAt
              periodStart
              periodEnd
              token
              createdAt
            }
          }
        }
      `}
      {...props}
    />
  )
}
