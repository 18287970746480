import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Text } from '@urbaninfrastructure/react-ui-kit'
import { isBikeMi, isInurbaRouen } from '../../lib/util'
import DefaultLogo from './DefaultLogo'
import { useTheme } from 'styled-components'
import BikeMiLogo from './BikeMiLogo'
import InurbaRouenLogo from './InurbaRouenLogo'
import { useSystemConfig } from '../../lib/use-system-config'

const Logo = ({
  title,
  color,
  companyName,
  displayMode = 'row',
  ...props
}: {
  title: string | null
  companyName: string | null
  color: string
  displayMode?: 'row' | 'column'
}) => {
  const theme = useTheme()
  const systemConfig = useSystemConfig()
  const bikeMi = isBikeMi(theme)
  const inurbaRouen = isInurbaRouen(systemConfig)

  return (
    <Flex
      alignItems="center"
      flexDirection={displayMode === 'column' ? 'column' : null}
    >
      <Text color={color}>
        {bikeMi && <BikeMiLogo {...props} />}
        {inurbaRouen && <InurbaRouenLogo {...props} />}
        {!bikeMi && !inurbaRouen && (
          <DefaultLogo width={47} height={47} {...props} />
        )}
      </Text>
      {title && (
        <Text ml={3} typoStyle="xxs" color={color}>
          {!bikeMi && !inurbaRouen && title} {!bikeMi && !inurbaRouen && '| '}{' '}
          {companyName ? (
            companyName
          ) : (
            <FormattedMessage
              id="components.Logo.title"
              defaultMessage="Business"
            />
          )}
        </Text>
      )}
    </Flex>
  )
}

export default Logo
