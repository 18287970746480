import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { Flex, Text, Dot, FlexProps } from '@urbaninfrastructure/react-ui-kit'

const pulseAnimation = keyframes`
  0%   { opacity: 1 }
  50%  { opacity: 0 }
  100% { opacity: 1 }
`

const Pulsing = styled(Flex)`
  animation: ${pulseAnimation} 1s infinite linear;
`

const Loading: FC<FlexProps> = ({
  children,
  bg = 'primary',
  color,
  ...props
}) => (
  <Pulsing fontSize={1} color={color as any} {...props}>
    <Dot bg={bg}>
      <Text mute>{children}</Text>
    </Dot>
  </Pulsing>
)

export default Loading
