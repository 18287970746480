/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settings
// ====================================================

export interface settings_system {
  __typename: "System";
  id: string;
  name: string;
  languageCodes: LanguageCode[] | null;
  currency: string | null;
  publicDomain: string | null;
  webUrl: string | null;
  businessUrl: string;
  contactEmail: string;
  contactNumber: string;
  paymentProvider: PaymentProvider | null;
}

export interface settings_me {
  __typename: "Me";
  id: string;
  preferredLanguageCode: LanguageCode | null;
}

export interface settings_company {
  __typename: "Company";
  id: string;
  name: string | null;
  organizationNumber: string | null;
  address: string | null;
  zipCode: string | null;
  city: string | null;
  receiptEmail: string | null;
  /**
   * Is the company approved for creating value codes?
   */
  active: boolean;
  collectionMethod: CollectionMethod;
}

export interface settings {
  intercomSettings: CORE_JSON | null;
  /**
   * Get the current system
   */
  system: settings_system | null;
  me: settings_me | null;
  company: settings_company | null;
  haveSanityBusinessPage: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: products
// ====================================================

export interface products_products {
  __typename: "Product";
  id: string;
  name: string;
  priceWithoutVat: CORE_Money | null;
  description: string | null;
}

export interface products {
  /**
   * Products that is enabled, redeemable and shown in lists
   */
  products: products_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product
// ====================================================

export interface product_product {
  __typename: "Product";
  id: string;
  name: string;
  priceWithoutVat: CORE_Money | null;
  description: string | null;
  imageUrl: string | null;
}

export interface product {
  product: product_product | null;
}

export interface productVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: valueCodeGroups
// ====================================================

export interface valueCodeGroups_valueCodeGroups {
  __typename: "ValueCodeGroup";
  groupName: string;
}

export interface valueCodeGroups {
  valueCodeGroups: valueCodeGroups_valueCodeGroups[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: valueCodesQuery
// ====================================================

export interface valueCodesQuery_valueCodes_result_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface valueCodesQuery_valueCodes_result_administrator {
  __typename: "Administrator";
  id: string;
  name: string;
}

export interface valueCodesQuery_valueCodes_result {
  __typename: "ValueCode";
  id: string;
  code: string;
  createdAt: CORE_DateTime;
  groupName: string;
  state: ValueCodeState;
  transport: ValueCodeTransport;
  receiverEmail: string | null;
  /**
   * The product for the value code. If it is null, it is either not enabled, deleted or not redeemable anymore
   */
  product: valueCodesQuery_valueCodes_result_product | null;
  administrator: valueCodesQuery_valueCodes_result_administrator;
}

export interface valueCodesQuery_valueCodes {
  __typename: "ValueCodeResult";
  count: number;
  result: valueCodesQuery_valueCodes_result[];
}

export interface valueCodesQuery {
  valueCodes: valueCodesQuery_valueCodes | null;
}

export interface valueCodesQueryVariables {
  groupName?: string | null;
  state?: ValueCodeState | null;
  perPage?: number | null;
  page?: number | null;
  all?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statistics
// ====================================================

export interface statistics_statistics {
  __typename: "SystemStatistics";
  name: string;
  sharedCodes: number;
  claimedCodes: number;
}

export interface statistics {
  statistics: statistics_statistics | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: deprecatedReceipts
// ====================================================

export interface deprecatedReceipts_orders {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  type: OrderType;
  amount: CORE_Money | null;
  amountWithoutVat: CORE_Money | null;
  currency: string | null;
  createdAt: CORE_DateTime;
}

export interface deprecatedReceipts {
  orders: deprecatedReceipts_orders[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: deprecatedReceipt
// ====================================================

export interface deprecatedReceipt_order_orderLines_product {
  __typename: "Product";
  id: string;
  description: string | null;
  name: string;
  priceWithoutVat: CORE_Money | null;
}

export interface deprecatedReceipt_order_orderLines_valueCodes {
  __typename: "OrderValueCode";
  id: string;
  code: string;
  redeemedAt: CORE_DateTime | null;
}

export interface deprecatedReceipt_order_orderLines {
  __typename: "OrderLine";
  id: string;
  product: deprecatedReceipt_order_orderLines_product | null;
  quantity: number | null;
  amountWithoutVat: CORE_Money | null;
  totalAmountWithoutVat: CORE_Money | null;
  vatRate: number | null;
  valueCodes: deprecatedReceipt_order_orderLines_valueCodes[];
}

export interface deprecatedReceipt_order_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string;
}

export interface deprecatedReceipt_order_company {
  __typename: "Company";
  id: string;
  name: string | null;
}

export interface deprecatedReceipt_order {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  type: OrderType;
  amount: CORE_Money | null;
  amountWithoutVat: CORE_Money | null;
  vatAmount: CORE_Money | null;
  currency: string | null;
  createdAt: CORE_DateTime;
  customerEmail: string | null;
  orderLines: (deprecatedReceipt_order_orderLines | null)[] | null;
  paymentTransactions: deprecatedReceipt_order_paymentTransactions[];
  creditCardMasked: string | null;
  company: deprecatedReceipt_order_company;
}

export interface deprecatedReceipt {
  order: deprecatedReceipt_order | null;
}

export interface deprecatedReceiptVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMe
// ====================================================

export interface updateMe_updateMe {
  __typename: "Me";
  id: string;
  preferredLanguageCode: LanguageCode | null;
}

export interface updateMe {
  updateMe: updateMe_updateMe;
}

export interface updateMeVariables {
  input: MeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: receipt
// ====================================================

export interface receipt_invoice_items_product {
  __typename: "InvoiceProduct";
  id: string;
  descriptionText: string | null;
  name: string;
  price: CORE_Money;
  vatRate: number;
}

export interface receipt_invoice_items {
  __typename: "InvoiceItem";
  id: string;
  product: receipt_invoice_items_product;
  quantity: number;
  amount: CORE_Money;
  totalAmount: CORE_Money;
  amountWithoutVat: CORE_Money | null;
  totalWithoutVat: CORE_Money | null;
}

export interface receipt_invoice_paymentMethodDetails_card {
  __typename: "InvoicePaymentMethodCard";
  expMonth: number;
  expYear: number;
  last4: string;
  brand: InvoiceCardBrand;
}

export interface receipt_invoice_paymentMethodDetails {
  __typename: "InvoicePaymentMethod";
  type: InvoicePaymentMethodType | null;
  card: receipt_invoice_paymentMethodDetails_card | null;
}

export interface receipt_invoice_company {
  __typename: "InvoiceCompany";
  id: string;
  name: string | null;
  receiptEmail: string | null;
}

export interface receipt_invoice {
  __typename: "Invoice";
  id: string;
  status: InvoiceStatus;
  description: string;
  collectionMethod: CollectionMethod;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money | null;
  currency: string;
  paidAt: CORE_DateTime | null;
  periodStart: CORE_DateTime | null;
  periodEnd: CORE_DateTime | null;
  token: string;
  createdAt: CORE_DateTime;
  items: receipt_invoice_items[];
  transactionNumber: string | null;
  paymentMethodDetails: receipt_invoice_paymentMethodDetails | null;
  company: receipt_invoice_company | null;
}

export interface receipt {
  invoice: receipt_invoice | null;
}

export interface receiptVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: receipts
// ====================================================

export interface receipts_invoices_rows {
  __typename: "Invoice";
  id: string;
  status: InvoiceStatus;
  description: string;
  collectionMethod: CollectionMethod;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money | null;
  currency: string;
  paidAt: CORE_DateTime | null;
  periodStart: CORE_DateTime | null;
  periodEnd: CORE_DateTime | null;
  token: string;
  createdAt: CORE_DateTime;
}

export interface receipts_invoices {
  __typename: "Invoices";
  rows: (receipts_invoices_rows | null)[] | null;
}

export interface receipts {
  /**
   * Invoices
   */
  invoices: receipts_invoices;
}

export interface receiptsVariables {
  draft?: boolean | null;
  open?: boolean | null;
  paid?: boolean | null;
  uncollectible?: boolean | null;
  sendInvoice?: boolean | null;
  chargeAutomatically?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteAdministrator
// ====================================================

export interface inviteAdministrator_inviteAdministrator {
  __typename: "InvitedCompanyAdministrator";
  id: string;
}

export interface inviteAdministrator {
  inviteAdministrator: inviteAdministrator_inviteAdministrator;
}

export interface inviteAdministratorVariables {
  email: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendAdministratorInvitation
// ====================================================

export interface resendAdministratorInvitation_resendAdministratorInvitation {
  __typename: "InvitedCompanyAdministrator";
  id: string;
  updatedAt: CORE_DateTime;
  expiresAt: CORE_DateTime;
}

export interface resendAdministratorInvitation {
  resendAdministratorInvitation: resendAdministratorInvitation_resendAdministratorInvitation;
}

export interface resendAdministratorInvitationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: companyAdministratorsQuery
// ====================================================

export interface companyAdministratorsQuery_companyAdministrators {
  __typename: "Administrator";
  id: string;
  name: string;
  email: string;
  createdAt: CORE_DateTime;
}

export interface companyAdministratorsQuery {
  companyAdministrators: companyAdministratorsQuery_companyAdministrators[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteAdministratorMutation
// ====================================================

export interface inviteAdministratorMutation_inviteAdministrator {
  __typename: "InvitedCompanyAdministrator";
  id: string;
  name: string;
  email: string;
  acceptedAt: CORE_DateTime | null;
  expiresAt: CORE_DateTime;
}

export interface inviteAdministratorMutation {
  inviteAdministrator: inviteAdministratorMutation_inviteAdministrator;
}

export interface inviteAdministratorMutationVariables {
  email: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendAdministratorInvitationMutation
// ====================================================

export interface resendAdministratorInvitationMutation_resendAdministratorInvitation {
  __typename: "InvitedCompanyAdministrator";
  id: string;
  updatedAt: CORE_DateTime;
  acceptedAt: CORE_DateTime | null;
  expiresAt: CORE_DateTime;
}

export interface resendAdministratorInvitationMutation {
  resendAdministratorInvitation: resendAdministratorInvitationMutation_resendAdministratorInvitation;
}

export interface resendAdministratorInvitationMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitedCompanyAdministratorsQuery
// ====================================================

export interface invitedCompanyAdministratorsQuery_invitedCompanyAdministrators {
  __typename: "InvitedCompanyAdministrator";
  id: string;
  email: string;
  name: string;
  acceptedAt: CORE_DateTime | null;
  expiresAt: CORE_DateTime;
  updatedAt: CORE_DateTime;
}

export interface invitedCompanyAdministratorsQuery {
  /**
   * Get invited company administrators
   */
  invitedCompanyAdministrators: invitedCompanyAdministratorsQuery_invitedCompanyAdministrators[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: billingTypesQuery
// ====================================================

export interface billingTypesQuery_company {
  __typename: "Company";
  id: string;
  collectionMethod: CollectionMethod;
}

export interface billingTypesQuery_system_collectionMethods {
  __typename: "SystemCollectionMethods";
  sendInvoice: boolean | null;
  chargeAutomatically: boolean | null;
}

export interface billingTypesQuery_system {
  __typename: "System";
  id: string;
  collectionMethods: billingTypesQuery_system_collectionMethods;
}

export interface billingTypesQuery {
  company: billingTypesQuery_company | null;
  /**
   * Get the current system
   */
  system: billingTypesQuery_system | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCompanyMutation
// ====================================================

export interface updateCompanyMutation_updateCompany {
  __typename: "Company";
  id: string;
  name: string | null;
  organizationNumber: string | null;
  address: string | null;
  zipCode: string | null;
  city: string | null;
  receiptEmail: string | null;
  /**
   * Is the company approved for creating value codes?
   */
  active: boolean;
  collectionMethod: CollectionMethod;
}

export interface updateCompanyMutation {
  updateCompany: updateCompanyMutation_updateCompany | null;
}

export interface updateCompanyMutationVariables {
  input?: CompanyInput | null;
  collectionMethod?: CollectionMethod | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptInvitationMutation
// ====================================================

export interface acceptInvitationMutation {
  /**
   * Accept invitation and set password. Returns a customToken that can be used to sign the user in.
   */
  acceptInvitation: string;
}

export interface acceptInvitationMutationVariables {
  password: string;
  verificationToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderState
// ====================================================

export interface orderState {
  orderState: OrderState | null;
}

export interface orderStateVariables {
  token: string;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPaymentMethodMutation
// ====================================================

export interface addPaymentMethodMutation_addPaymentMethod {
  __typename: "PaymentSetupSession";
  /**
   * Redirect user to web page that handles redirection to provider's page
   */
  redirectUrl: string;
}

export interface addPaymentMethodMutation {
  /**
   * Initiates payment method setup flow, same as Checkout but without charging
   * User token is required
   */
  addPaymentMethod: addPaymentMethodMutation_addPaymentMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removePaymentMethodMutation
// ====================================================

export interface removePaymentMethodMutation_removePaymentMethod {
  __typename: "RemovePaymentMethodResponse";
  /**
   * ID of the payment method that was deleted
   */
  paymentMethodId: string;
}

export interface removePaymentMethodMutation {
  /**
   * Removes user's payment method
   * User token is required
   */
  removePaymentMethod: removePaymentMethodMutation_removePaymentMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: companyPaymentMethodQuery
// ====================================================

export interface companyPaymentMethodQuery_company_paymentMethod {
  __typename: "Card";
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export interface companyPaymentMethodQuery_company {
  __typename: "Company";
  id: string;
  paymentMethod: companyPaymentMethodQuery_company_paymentMethod | null;
}

export interface companyPaymentMethodQuery {
  company: companyPaymentMethodQuery_company | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: registerCompanyMutation
// ====================================================

export interface registerCompanyMutation_registerCompany {
  __typename: "RegisterCompanyResponse";
  /**
   * Custom Firebase token. Must be used with firebase.auth().signInWithCustomToken
   */
  token: string;
}

export interface registerCompanyMutation {
  registerCompany: registerCompanyMutation_registerCompany;
}

export interface registerCompanyMutationVariables {
  company: CompanyInput;
  administrator: CreateAdministratorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createValueCodesByEmail
// ====================================================

export interface createValueCodesByEmail_createValueCodesByEmail {
  __typename: "ValueCode";
  groupName: string;
}

export interface createValueCodesByEmail {
  createValueCodesByEmail: createValueCodesByEmail_createValueCodesByEmail[];
}

export interface createValueCodesByEmailVariables {
  input: CreateValueCodesByEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createValueCodes
// ====================================================

export interface createValueCodes_createValueCodes {
  __typename: "ValueCode";
  groupName: string;
}

export interface createValueCodes {
  createValueCodes: createValueCodes_createValueCodes[];
}

export interface createValueCodesVariables {
  input: CreateValueCodesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createValueCodesByPhoneNumber
// ====================================================

export interface createValueCodesByPhoneNumber_createValueCodesByPhoneNumber {
  __typename: "ValueCode";
  groupName: string;
}

export interface createValueCodesByPhoneNumber {
  createValueCodesByPhoneNumber: createValueCodesByPhoneNumber_createValueCodesByPhoneNumber[];
}

export interface createValueCodesByPhoneNumberVariables {
  input: CreateValueCodesByPhoneNumberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkPaymentSetupCompletionQuery
// ====================================================

export interface checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompleted {
  __typename: "AsyncProcessCompleted";
}

export interface checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompletionPending {
  __typename: "AsyncProcessCompletionPending";
  checkAfterMilliseconds: number;
}

export type checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion = checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompleted | checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompletionPending;

export interface checkPaymentSetupCompletionQuery {
  /**
   * Check payment setup completion
   */
  checkPaymentSetupCompletion: checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion;
}

export interface checkPaymentSetupCompletionQueryVariables {
  setupToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GlobalMe
// ====================================================

export interface GlobalMe {
  __typename: "Me";
  id: string;
  preferredLanguageCode: LanguageCode | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyFragment
// ====================================================

export interface CompanyFragment {
  __typename: "Company";
  id: string;
  name: string | null;
  organizationNumber: string | null;
  address: string | null;
  zipCode: string | null;
  city: string | null;
  receiptEmail: string | null;
  /**
   * Is the company approved for creating value codes?
   */
  active: boolean;
  collectionMethod: CollectionMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CardBrand {
  american_express = "american_express",
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  maestro = "maestro",
  mastercard = "mastercard",
  unionpay = "unionpay",
  unknown = "unknown",
  visa = "visa",
}

export enum CollectionMethod {
  charge_automatically = "charge_automatically",
  send_invoice = "send_invoice",
}

export enum InvoiceCardBrand {
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  mastercard = "mastercard",
  unionpay = "unionpay",
  unknown = "unknown",
  visa = "visa",
}

export enum InvoicePaymentMethodType {
  card = "card",
}

export enum InvoiceStatus {
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
}

export enum LanguageCode {
  de = "de",
  en = "en",
  fr = "fr",
  it = "it",
  nb = "nb",
  pl = "pl",
  sv = "sv",
  uk = "uk",
}

export enum OrderState {
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  ERROR = "ERROR",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  PAYMENT_NOT_FOUND = "PAYMENT_NOT_FOUND",
  PENDING_COMPLETE = "PENDING_COMPLETE",
  SUCCESS = "SUCCESS",
}

export enum OrderStatus {
  cancelled = "cancelled",
  complete_failed = "complete_failed",
  completed = "completed",
  completion_postponed = "completion_postponed",
  created = "created",
  declined = "declined",
  failed = "failed",
  paid = "paid",
  partially_refunded = "partially_refunded",
  payment_failed = "payment_failed",
  payment_postponed = "payment_postponed",
  pending = "pending",
  pending_payment = "pending_payment",
  refunded = "refunded",
  scheduled = "scheduled",
}

export enum OrderType {
  business_registration_fee = "business_registration_fee",
  business_regular_charge = "business_regular_charge",
  declined = "declined",
}

export enum PaymentProvider {
  stripe = "stripe",
  swedbank_pay = "swedbank_pay",
}

export enum ValueCodeState {
  redeemed = "redeemed",
  revoked = "revoked",
  unused = "unused",
}

export enum ValueCodeTransport {
  email = "email",
  manual = "manual",
}

export interface CompanyInput {
  name: string;
  organizationNumber: string;
  address: string;
  zipCode: string;
  city: string;
  receiptEmail: string;
}

export interface CreateAdministratorInput {
  name: string;
  email: string;
  password: string;
}

export interface CreateValueCodesByEmailInput {
  groupName: string;
  productId: string;
  emails: string[];
  message?: string | null;
}

export interface CreateValueCodesByPhoneNumberInput {
  groupName: string;
  productId: string;
  phoneNumbers: string[];
}

export interface CreateValueCodesInput {
  groupName: string;
  productId: string;
  quantity: number;
}

export interface MeInput {
  preferredLanguageCode?: LanguageCode | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
