import * as React from 'react'

const InurbaRouenLogo = ({
  width = 100,
  height = 100,
  alt = 'Lovelo',
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      width={width}
      height={height}
      src="/static/assets/inurba-rouen-logo.svg"
      alt={alt}
    />
  )
}

export default InurbaRouenLogo
