import React from 'react'

const BikeMiLogo = ({
  width = 180,
  height = 54,
  alt = '',
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      width={width}
      height={height}
      src="/static/assets/bikemi.svg"
      alt={alt}
    />
  )
}

export default BikeMiLogo
