import React from 'react'
import styled, { css } from 'styled-components'
import { Next } from '@urbaninfrastructure/react-icons'
import { Box, Flex } from '@urbaninfrastructure/react-ui-kit'

const IconLink = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const Card = styled(Box)`
  text-decoration: none;
  transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1),
    box-shadow 400ms cubic-bezier(0.19, 1, 0.22, 1);
`

Card.defaultProps = {
  backgroundColor: 'white',
  display: 'block',
  position: 'relative',
  height: '100%',
  borderRadius: 'md',
  boxShadow: 'heavy',
  p: 'xs',
}

export const CardAnchor = styled(Box.withComponent('a'))`
  ${({ theme }) => css`
    text-decoration: none;
    transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1),
      box-shadow 400ms cubic-bezier(0.19, 1, 0.22, 1);
    &:hover,
    &:focus,
    &:hover:not(:active) {
      transform: translate(0, -0.15rem);
      box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.15),
        0px 10px 10px rgba(0, 0, 0, 0.15);
      ${IconLink} {
        svg {
          color: ${theme.colors.primaryDark};
        }
      }
    }
    &:focus {
      outline-offset: 2px;
      outline-color: white;
    }
  `}
`

CardAnchor.defaultProps = {
  backgroundColor: 'white',
  display: 'block',
  position: 'relative',
  height: '100%',
  borderRadius: 'md',
  boxShadow: 'heavy',
  p: 'xs',
}

export const CardLink = React.forwardRef<
  HTMLAnchorElement,
  { children: React.ReactNode }
>(function CardLink({ children, ...props }, ref) {
  return (
    <CardAnchor ref={ref} {...props}>
      <IconLink>
        <Next size="32px" color="primaryIcon" />
      </IconLink>
      <Flex flexDirection="column" height="100%" justifyContent="flex-start">
        {children}
      </Flex>
    </CardAnchor>
  )
})
