import React from 'react'
import Downshift from 'downshift'
import {
  Box,
  Input,
  Dropdown,
  DropdownItem,
  FormField,
} from '@urbaninfrastructure/react-ui-kit'

type Props = {
  id: string
  onChange: (values: any) => void
  onInputValueChange: (values: any) => void
  label: string
  items: Array<any>
  inputValue: string
  autoFocus?: boolean
  errorMessage?: React.ReactNode
}

const SearchSelect = ({
  id,
  onChange,
  onInputValueChange,
  label,
  items,
  inputValue,
  autoFocus,
  errorMessage,
  ...props
}: Props) => {
  return (
    <Box mb="sm">
      <Downshift
        id={id}
        onChange={onChange}
        inputValue={inputValue}
        itemToString={(item) => (item ? item.value : '')}
        onInputValueChange={onInputValueChange}
        stateReducer={(state, changes) => {
          switch (changes.type) {
            case Downshift.stateChangeTypes.mouseUp:
            case Downshift.stateChangeTypes.blurInput:
              return { ...state, isOpen: false }
            default:
              return changes
          }
        }}
        {...props}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <Box
            {...getRootProps()}
            aria-labelledby={undefined}
            position="relative"
          >
            <FormField
              labelProps={getLabelProps()}
              label={label}
              errorMessage={errorMessage}
            >
              <Input {...getInputProps()} autoFocus={autoFocus} alternative />
            </FormField>
            {isOpen && items && (
              <Dropdown
                {...getMenuProps()}
                style={{ maxHeight: '20rem', overflowY: 'auto' }}
              >
                {items.map((item, index) => {
                  return (
                    <DropdownItem
                      key={item.value}
                      selected={
                        selectedItem === item || highlightedIndex === item
                      }
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? '#EFF1F4' : undefined,
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                    >
                      {item.value}
                    </DropdownItem>
                  )
                })}
              </Dropdown>
            )}
          </Box>
        )}
      </Downshift>
    </Box>
  )
}

export default SearchSelect
