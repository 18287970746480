import React, { FC } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import styled, { css } from 'styled-components'
import { Box, Flex } from '../../components'
import Link, { LinkProps } from '../Link'

type PillRoute = {
  active: boolean
  linkProps: LinkProps
  message: MessageDescriptor
}

const Container = styled(Flex)`
  ${({ theme }) => css`
    background-color: ${theme.colors.formGray};
    border-radius: 999px;
    position: relative;
    padding: ${theme.space[2]}px ${theme.space[1]}px;
  `}
`

const Anchor = styled.a<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    font-size: ${theme.fontSizes[1]}px;
    overflow: hidden;
    padding: ${theme.space[2]}px ${theme.space[6]}px;
    position: relative;
    border-radius: 1.125rem;
    text-decoration: none;
    transition: background-color 200ms cubic-bezier(0.19, 1, 0.22, 1);
    &:hover,
    &:focus {
      cursor: pointer;
      color: ${theme.colors.primaryText};
      ${!selected &&
      `
        background-color: ${theme.colors.neutral[2]};
      `}
    }

    input:focus + span,
    input:checked + span {
      color: ${theme.colors.primary};
    }
  `}
`

const Pill = styled.div`
  ${({ theme }) => css`
    background: #fff;
    border-radius: 999px;
    border: 1px solid transparent;
    box-shadow: ${theme.shadows.light};
    height: calc(100% - ${theme.space[1] * 2}px);
    opacity: 0;
    position: absolute;
    top: ${theme.space[1]}px;
    transition: left 175ms ease-out, width 175ms ease-out, opacity 200ms;
    will-change: left, width;
  `}
`

const PillItem: FC<{
  active: boolean
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  linkProps: LinkProps
  setPillProperties: (props: { width: number; offsetLeft: number }) => void
}> = ({ active, children, linkProps, onClick, setPillProperties }) => {
  const ref = React.useCallback((node) => {
    if (node !== null) {
      setPillProperties({
        width: node.getBoundingClientRect().width,
        offsetLeft: node.offsetLeft,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box ref={ref}>
      <Link scroll={false} {...linkProps}>
        <Anchor onClick={onClick} selected={active}>
          {children}
        </Anchor>
      </Link>
    </Box>
  )
}

const PillNavigation = ({
  routes,
  onChange = () => void 0,
  ...props
}: {
  'data-testid'?: string
  onChange?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  routes: PillRoute[]
}) => {
  const pillRef = React.useRef<HTMLDivElement>(null)
  const [pillProperties, setPillProperties] = React.useState<
    Array<{ width: number; offsetLeft: number }>
  >([])
  const activeRouteIndex = routes.findIndex((route) => route.active)
  const activePillProperties = pillProperties[activeRouteIndex]

  React.useLayoutEffect(() => {
    if (pillRef.current && activePillProperties) {
      if (activePillProperties.offsetLeft) {
        pillRef.current.style.left = `${activePillProperties.offsetLeft}px`
      }
      if (activePillProperties.width) {
        pillRef.current.style.width = `${activePillProperties.width}px`
      }
    }
  }, [pillRef, activePillProperties])

  React.useLayoutEffect(() => {
    if (pillRef.current) {
      pillRef.current.style.opacity = '1'
    }
  }, [pillRef])

  return (
    <Container data-testid={props['data-testid']}>
      <Pill ref={pillRef} />

      {routes.map(({ active, message, linkProps }, i) => {
        return (
          <PillItem
            key={message.id}
            active={active}
            linkProps={linkProps}
            onClick={onChange}
            setPillProperties={({ width, offsetLeft }) => {
              setPillProperties((pillProperties) => {
                const newPillProperties = [...pillProperties]
                newPillProperties[i] = { width, offsetLeft }
                return newPillProperties
              })
            }}
          >
            {message && <FormattedMessage {...message} />}
          </PillItem>
        )
      })}
    </Container>
  )
}

export default PillNavigation
