import React, { FC } from 'react'
import NextRouter from 'next/router'
import NextLink from 'next/link'
import { UrlObject } from 'url'

type Url = UrlObject | string

type Route =
  | '/'
  | '/forgot-password'
  | '/receipts'
  | '/receipts/receipt'
  | '/registration'
  | '/new-password'
  | '/value-codes'
  | '/value-codes/groups'
  | '/value-codes/confirmation'
  | '/value-codes/create'
  | '/administrators'
  | '/administrators/invite'
  | '/company'
  | '/company/edit'
  | Url

export type LinkProps = {
  href: Route
  as?: Url
  replace?: boolean
  scroll?: boolean
  shallow?: boolean
  passHref?: boolean
  prefetch?: boolean
}

const Link: FC<LinkProps> = ({ passHref = true, ...props }) => {
  return <NextLink passHref={passHref} {...props} />
}

export default Link

export const CreateValueCodeLink: FC<{
  productId: string
  passHref?: boolean
}> = ({ productId, children, passHref }) => {
  return (
    <Link
      href="/value-codes/create/[productId]"
      as={`/value-codes/create/${productId}`}
      passHref={passHref}
    >
      {children}
    </Link>
  )
}

export const MeLink: FC<{
  mode?: 'edit'
  passHref?: boolean
}> = ({ mode, children, passHref = true }) => {
  const query: { mode?: 'edit' } = {}
  if (mode) {
    query.mode = mode
  }
  return (
    <NextLink
      href={{
        pathname: '/administrators/me',
        query,
      }}
      passHref={passHref}
    >
      {children}
    </NextLink>
  )
}

export function pushRoute(
  props: Url,
  as?: string,
  options?: { shallow: boolean }
) {
  return NextRouter.push(props, as, options)
}

export function replaceRoute(
  props: Url,
  as?: string,
  options?: { shallow: boolean }
) {
  return NextRouter.replace(props, as, options)
}
