import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import {
  CheckmarkAlt as CheckIcon,
  CloseAlt as CloseIcon,
  MailAlt as MailIcon,
  Warning,
} from '@urbaninfrastructure/react-icons'
import {
  Anchor,
  Flex,
  Table,
  Th,
  Td,
  Tr,
  Text,
  Alert,
} from '@urbaninfrastructure/react-ui-kit'
import ValueCodesDownload from '../ValueCodesDownload'
import {
  valueCodesQuery_valueCodes_result,
  valueCodesQueryVariables,
  ValueCodeState,
  ValueCodeTransport,
} from '../../core-types'

const State: FC<{
  value: ValueCodeState
  transport: valueCodesQuery_valueCodes_result['transport']
}> = ({ value, transport }) => {
  switch (value) {
    case ValueCodeState.unused:
      return (
        <Flex alignItems="center">
          {transport && transport === 'email' ? (
            <>
              <MailIcon color="neutral.5" mr={2} />
              <FormattedMessage
                id="components.ValueCodesTable.States.Shared"
                defaultMessage="Shared"
              />
            </>
          ) : (
            <FormattedMessage
              id="components.ValueCodesTable.States.Created"
              defaultMessage="Created"
            />
          )}
        </Flex>
      )

    case ValueCodeState.redeemed:
      return (
        <Flex alignItems="center">
          <CheckIcon color="paletteLight.green01" mr={2} />

          <FormattedMessage
            id="components.ValueCodesTable.States.Claimed"
            defaultMessage="Claimed"
          />
        </Flex>
      )

    case ValueCodeState.revoked:
      return (
        <Flex alignItems="center">
          <CloseIcon color="neutral.5" mr={2} />

          <FormattedMessage
            id="components.ValueCodesTable.States.Revoked"
            defaultMessage="Revoked"
          />
        </Flex>
      )
  }
}

const Transport: FC<{
  value: ValueCodeTransport
  receiverEmail: string | null
}> = ({ value, receiverEmail }) => {
  switch (value) {
    case ValueCodeTransport.manual:
      return (
        <FormattedMessage
          id="components.ValueCodesTransports.Manual"
          defaultMessage="Shared manually"
        />
      )
    case ValueCodeTransport.email: {
      return <>{receiverEmail}</>
    }
  }
}

const ValueCodesTable = ({
  showDownload = false,
  valueCodes,
  variables,
}: {
  showDownload?: boolean
  valueCodes: valueCodesQuery_valueCodes_result[]
  variables: valueCodesQueryVariables
}) => {
  const haveValueCodesWithoutProduct = valueCodes.some((v) => !v.product)
  return (
    <>
      {haveValueCodesWithoutProduct && (
        <Alert variant="error" mb="xs">
          <FormattedMessage
            id="have_value_codes_without_product"
            defaultMessage="There are value codes without any products. This means that the value codes is not redeemable."
          />
        </Alert>
      )}
      <Table small variant="simple" data-testid="ValueCodesTable">
        <thead>
          <Tr>
            <Th style={{ verticalAlign: 'middle' }}>
              <Text pl="xxs">
                <FormattedMessage
                  id="components.ValueCodesTable.Headers.Product"
                  defaultMessage="Product"
                />
              </Text>
            </Th>

            <Th style={{ verticalAlign: 'middle' }}>
              <FormattedMessage
                id="components.ValueCodesTable.Headers.ValueCode"
                defaultMessage="Voucher code"
              />
            </Th>

            <Th style={{ verticalAlign: 'middle' }}>
              <FormattedMessage
                id="components.ValueCodesTable.Headers.State"
                defaultMessage="State"
              />
            </Th>

            <Th style={{ verticalAlign: 'middle' }}>
              <FormattedMessage
                id="components.ValueCodesTable.Headers.Recipient"
                defaultMessage="Recipient"
              />
            </Th>

            <Th style={{ verticalAlign: 'middle' }}>
              <FormattedMessage
                id="components.ValueCodesTable.Headers.Shared"
                defaultMessage="Shared"
              />
            </Th>

            <Th style={{ verticalAlign: 'middle' }}>
              <FormattedMessage
                id="components.ValueCodesTable.Headers.By"
                defaultMessage="By"
              />
            </Th>
          </Tr>
        </thead>

        <tbody>
          {valueCodes.map(
            ({
              administrator,
              code,
              product,
              createdAt,
              state,
              transport,
              receiverEmail,
            }) => {
              const revoked = state === 'revoked'
              const textDecoration = revoked ? 'line-through' : ''

              return (
                <Tr key={code}>
                  <Td
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    <Text
                      bold
                      mute={revoked}
                      style={{
                        textDecoration: textDecoration,
                      }}
                      ml="xxs"
                    >
                      {product ? (
                        product.name
                      ) : (
                        <Flex alignItems="center">
                          <Warning size={14} mr={1} color="state.error" />{' '}
                          <FormattedMessage
                            id="value_code_product_not_found"
                            defaultMessage="Product does not exist"
                          />
                        </Flex>
                      )}
                    </Text>
                  </Td>

                  <Td style={{ verticalAlign: 'middle' }}>
                    <Text
                      data-testid="ValueCodesTable__Code"
                      as="span"
                      display="inline-block"
                      fontFamily="mono"
                      small
                      style={{ textDecoration: textDecoration }}
                      mute={revoked}
                    >
                      {code}
                    </Text>
                  </Td>

                  <Td style={{ verticalAlign: 'middle' }}>
                    <State value={state} transport={transport} />
                  </Td>

                  <Td
                    style={{
                      textDecoration: textDecoration,
                      verticalAlign: 'middle',
                    }}
                  >
                    <Text mute={revoked}>
                      <Transport
                        value={transport}
                        receiverEmail={receiverEmail}
                      />
                    </Text>
                  </Td>

                  <Td
                    style={{
                      textDecoration: textDecoration,
                      verticalAlign: 'middle',
                    }}
                  >
                    <Text mute={revoked}>
                      <FormattedDate
                        value={createdAt}
                        year="numeric"
                        month="long"
                        day="numeric"
                      />
                    </Text>
                  </Td>

                  <Td
                    style={{
                      textDecoration: textDecoration,
                      verticalAlign: 'middle',
                    }}
                  >
                    <Text mute={revoked}>{administrator.name}</Text>
                  </Td>
                </Tr>
              )
            }
          )}
        </tbody>

        {showDownload && (
          <tfoot>
            <Tr>
              <Td colSpan={3}>
                <ValueCodesDownload variables={variables}>
                  {(props) => (
                    <Anchor {...props}>
                      <FormattedMessage
                        id="components.ValueCodesTable.Footer.DownloadCsv"
                        defaultMessage="Download as CSV"
                      />
                    </Anchor>
                  )}
                </ValueCodesDownload>
              </Td>
            </Tr>
          </tfoot>
        )}
      </Table>
    </>
  )
}

export default ValueCodesTable
