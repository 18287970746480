import { ApolloError } from 'apollo-client'
import { CustomFormats } from 'react-intl'
import { ValueCodeState } from '../core-types'
import { SystemConfigClient } from '../types'

export const isServer: boolean = typeof window === 'undefined'

export function getFormats(currency: string): CustomFormats {
  return {
    date: {
      'year-only': {
        year: 'numeric',
      },
    },
    number: {
      currency: {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  }
}

export const checkForError = (
  code:
    | 'FORBIDDEN'
    | 'UNAUTHENTICATED'
    | 'COMPANY_ADMINISTRATOR_NOT_FOUND'
    | 'ADMINISTRATOR_ALREADY_INVITED'
    | 'ADMINISTRATOR_ALREADY_REGISTERED',
  error?: ApolloError
) =>
  error &&
  error.graphQLErrors &&
  error.graphQLErrors.find((e) => e.extensions && e.extensions.code === code)

export function isEdinburgh(theme: { name: string }): boolean {
  return theme.name === 'edinburghCityBike'
}
export function isBikeMi(theme: { name: string }): boolean {
  return theme.name === 'bikeMi'
}

export function isInurbaRouen(systemConfig: SystemConfigClient): boolean {
  return systemConfig.id === 'inurba-rouen'
}

export function validateAndSplitArray(
  array: Array<string>,
  isValid: (value: string) => any
) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}

export function isEmail(value: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
}

export function getFaviconKey(
  systemConfig: SystemConfigClient
): string | undefined {
  return systemConfig.faviconKey || systemConfig.colourKey
}

export function getQueryString(
  str: string | string[] | undefined
): string | undefined {
  if (Array.isArray(str)) {
    return str[0]
  }
  return str
}

export const isValueCodeState = (
  x: string | ValueCodeState
): x is ValueCodeState => {
  return !!ValueCodeState[x]
}
