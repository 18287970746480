import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Parser } from 'json2csv'
import { ValueCodesQuery } from '../../lib/core'
import { valueCodesQueryVariables } from '../../core-types'

const messages = defineMessages({
  shared: {
    id: 'lib.withValueCodesDownload.states.shared',
    defaultMessage: 'Shared',
  },
  claimed: {
    id: 'lib.withValueCodesDownload.states.claimed',
    defaultMessage: 'Claimed',
  },
  revoked: {
    id: 'lib.withValueCodesDownload.states.revoked',
    defaultMessage: 'Revoked',
  },
  manual: {
    id: 'lib.withValueCodesDownload.transports.manual',
    defaultMessage: 'Manual',
  },
  product: {
    id: 'lib.valueCodeDownload.labels.Product',
    defaultMessage: 'Product',
  },
  code: {
    id: 'lib.valueCodeDownload.labels.valueCode',
    defaultMessage: 'Voucher code',
  },
  state: {
    id: 'lib.valueCodeDownload.labels.State',
    defaultMessage: 'State',
  },
  recipient: {
    id: 'lib.valueCodeDownload.labels.Recipient',
    defaultMessage: 'Recipient',
  },
  by: {
    id: 'lib.valueCodeDownload.labels.By',
    defaultMessage: 'By',
  },
})

type CSVParser = {
  product: {
    name: string
  } | null
  code: string
  state: string
  transport: string
  createdAt: string
  administrator: {
    name: string
  }
}

const ValueCodesDownload = ({
  children,
  variables,
}: {
  children: (obj: {
    disabled?: true
    onClick?: () => void
  }) => React.ReactElement
  variables: valueCodesQueryVariables
}) => {
  const intl = useIntl()
  const [startDownload, setStartDownload] = React.useState(false)

  const translateState = (state: string) => {
    switch (state) {
      case 'unused':
        return intl.formatMessage(messages.shared)
      case 'redeemed':
        return intl.formatMessage(messages.claimed)
      case 'revoked':
        return intl.formatMessage(messages.revoked)
    }

    return ''
  }

  const translateTransport = (transport: string) => {
    switch (transport) {
      case 'manual':
        return intl.formatMessage(messages.manual)
    }

    return ''
  }

  const fields = [
    {
      value: 'product.name',
      label: intl.formatMessage(messages.product),
    },
    {
      value: 'code',
      label: intl.formatMessage(messages.code),
    },
    {
      value: ({ state }: CSVParser) => translateState(state),
      label: intl.formatMessage(messages.state),
    },
    {
      value: ({ transport }: CSVParser) => translateTransport(transport),
      label: intl.formatMessage(messages.recipient),
    },
    {
      value: ({ createdAt }: CSVParser) =>
        intl.formatDate(createdAt, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      label: intl.formatMessage(messages.shared),
    },
    {
      value: 'administrator.name',
      label: intl.formatMessage(messages.by),
    },
  ]
  if (startDownload) {
    return (
      <ValueCodesQuery
        fetchPolicy="no-cache"
        variables={{ ...variables, all: true }}
        notifyOnNetworkStatusChange={true}
      >
        {({ data, loading }) => {
          if (loading) {
            return children({ disabled: true })
          }
          if (!data || !data.valueCodes) {
            return null
          }

          const valueCodes = data.valueCodes.result

          const parser = new Parser<CSVParser>({ fields })
          const csvData = encodeURIComponent(parser.parse(valueCodes))
          const href = `data:text/csv;charset=utf-8,${csvData}`

          window.location.href = href
          setStartDownload(false)
          return null
        }}
      </ValueCodesQuery>
    )
  }
  return children({ onClick: () => setStartDownload(true) })
}

export default ValueCodesDownload
