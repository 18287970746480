import styled from 'styled-components'
import {
  color,
  space,
  fontSize,
  compose,
  ColorProps,
  SpaceProps,
  FontSizeProps,
} from 'styled-system'

const Form = styled.form<SpaceProps>`
  ${space};
`

export const Legend = styled.legend<ColorProps & SpaceProps & FontSizeProps>`
  ${compose(color, space, fontSize)};
`

Legend.defaultProps = {
  fontSize: 3,
  color: 'neutral.5',
  mb: 2,
}
Legend.displayName = 'Legend'

export default Form
