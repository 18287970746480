import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputProps,
  Input,
  InputGroup,
  InputGroupAddon,
} from '@urbaninfrastructure/react-ui-kit'

export default function PasswordInput(
  props: InputProps & { alternative?: boolean }
) {
  const [mask, setMasked] = React.useState(true)
  const { ref, as, type, ...inputProps } = props
  return (
    <InputGroup>
      <Input type={mask ? 'password' : 'text'} {...inputProps} />
      <InputGroupAddon
        as="button"
        type="button"
        fontSize={1}
        alternative={props.alternative}
        onClick={() => setMasked(!mask)}
      >
        {mask && (
          <FormattedMessage
            id="components.PasswordInput.show"
            defaultMessage="Show"
          />
        )}
        {!mask && (
          <FormattedMessage
            id="components.PasswordInput.hide"
            defaultMessage="Hide"
          />
        )}
      </InputGroupAddon>
    </InputGroup>
  )
}
