import React, { createContext, useContext } from 'react'
import type { SystemConfigClient } from '../types'

const SystemConfigContext = createContext<{
  systemConfig?: SystemConfigClient
}>({
  systemConfig: undefined,
})

export const SystemConfigProvider = ({
  systemConfig,
  children,
}: {
  systemConfig?: SystemConfigClient
  children: React.ReactElement
}) => {
  return (
    <SystemConfigContext.Provider
      value={{
        systemConfig,
      }}
    >
      {children}
    </SystemConfigContext.Provider>
  )
}

export function useSystemConfig(): SystemConfigClient {
  const { systemConfig } = useContext(SystemConfigContext)

  if (!systemConfig) {
    throw new Error('SystemConfig not found')
  }

  return systemConfig
}
